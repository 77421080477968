import { useCallback, useMemo, useState } from "react";
import { useAuth0 } from "domains/auth/components/AuthProvider";
import { isEmailValid } from "domains/forms/isEmailValid";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import Button from "domains/ui/components/Button";
import ScenarioInput from "domains/ui/components/ScenarioInput";

import { Flex, HStack, PinInput, PinInputField, Text } from "@chakra-ui/react";

enum FormState {
  EmailInput,
  CodeInput,
  SpecialPasswordInputE2E,
}

export default function LoginForm() {
  const { errorToast } = useScenarioToast();
  const [email, setEmail] = useState("");
  const [isProcessingEmail, setIsProcessingEmail] = useState(false);
  const [formState, setFormState] = useState<FormState>(FormState.EmailInput);
  const { requestCode, processCode, signInWithPassword } = useAuth0();
  const [isCodeInvalid, setIsCodeInvalid] = useState(false);
  const [isProcessingCode, setIsProcessingCode] = useState(false);
  const [password, setPassword] = useState("");
  const [isProcessingPassword, setIsProcessingPassword] = useState(false);

  const isEmailInvalid = useMemo(() => !isEmailValid(email), [email]);

  const [wasEmailBlurred, setWasEmailBlurred] = useState(false);

  const showEmailInvalid = useMemo(
    () => wasEmailBlurred && isEmailInvalid && email,
    [wasEmailBlurred, isEmailInvalid, email]
  );

  const onSignIn = useCallback(async () => {
    if (isEmailInvalid) {
      return;
    }
    if (email === "cypress@scenario.gg") {
      setFormState(FormState.SpecialPasswordInputE2E);
      return;
    }
    setIsProcessingEmail(true);
    if (window.tolt_referral) {
      window.tolt.signup(email);
    }
    try {
      await requestCode(email);
      setFormState(FormState.CodeInput);
      setIsProcessingEmail(false);
    } catch (_) {
      setIsProcessingEmail(false);
      errorToast({
        title: "There was an error processing your request. Please try again.",
      });
    }
  }, [email, requestCode, isEmailInvalid, errorToast]);

  const handleSetEmail = useCallback(
    (newEmail: string) => {
      setEmail(newEmail);
      setWasEmailBlurred(false);
    },
    [setEmail]
  );

  const onVerify = useCallback(
    async (code: string) => {
      setIsProcessingCode(true);
      try {
        await processCode(email, code);
        setIsProcessingCode(false);
      } catch (error: any) {
        setIsProcessingCode(false);
        if (error.code === "access_denied") {
          setIsCodeInvalid(true);
        } else {
          errorToast({
            title:
              "There was an error processing your request. Please try again.",
          });
        }
      }
    },
    [email, processCode, errorToast]
  );

  const onSignInWithPassword = useCallback(async () => {
    if (!password) {
      return;
    }
    setIsProcessingPassword(true);
    try {
      await signInWithPassword(email, password);
      setIsProcessingPassword(false);
    } catch (_) {
      setIsProcessingPassword(false);
      errorToast({
        title: "There was an error processing your request. Please try again.",
      });
    }
  }, [password, email, signInWithPassword, errorToast]);

  return (
    <Flex direction="column" gap={4}>
      {formState === FormState.EmailInput && (
        <>
          <Text textAlign={"center"} size="title.md">
            Enter your email to get started
          </Text>
          <ScenarioInput
            disabled={isProcessingEmail}
            autoFocus={true}
            onBlur={() => setWasEmailBlurred(true)}
            setValue={handleSetEmail}
            onEnter={onSignIn}
            placeholder="Email address"
            type="email"
            value={email}
            borderColor={showEmailInvalid ? "danger.500" : undefined}
          />
          {showEmailInvalid && (
            <Text color="danger.500" size="body.md">
              Please enter a valid email address
            </Text>
          )}
          <Button
            variant="primary"
            colorScheme="white"
            onClick={onSignIn}
            isDisabled={isEmailInvalid || isProcessingEmail}
            w="100%"
          >
            Continue
          </Button>
        </>
      )}
      {formState === FormState.CodeInput && (
        <>
          <Text textAlign={"center"} size="title.md">
            Enter your verification code
          </Text>
          <Text textAlign={"center"} size="body.lg">
            An email with the code has been sent to {email}
          </Text>
          <HStack>
            <PinInput
              autoFocus={true}
              isDisabled={isProcessingCode}
              isInvalid={isCodeInvalid}
              onChange={() => setIsCodeInvalid(false)}
              onComplete={onVerify}
              otp
              placeholder=""
              size="lg"
            >
              {[...Array(6)].map((_, index) => (
                <PinInputField
                  key={`pin-input-${index}`}
                  autoComplete="off"
                  // disable dashlane
                  data-form-type="other"
                  // disable lastpass
                  data-lpignore="true"
                />
              ))}
            </PinInput>
          </HStack>
          {isCodeInvalid && (
            <Text color="danger.500" size="body.md">
              Please enter a valid code
            </Text>
          )}
        </>
      )}
      {
        // This is a special case for Cypress E2E tests
        // It uses password instead of code
        formState === FormState.SpecialPasswordInputE2E && (
          <>
            <ScenarioInput
              disabled={isProcessingPassword}
              autoFocus={true}
              setValue={setPassword}
              onEnter={onSignInWithPassword}
              placeholder="Password"
              type="password"
              value={password}
            />
            <Button
              variant="primary"
              colorScheme="white"
              onClick={onSignInWithPassword}
              isDisabled={!password || isProcessingPassword}
              w="100%"
            >
              Continue
            </Button>
          </>
        )
      }
    </Flex>
  );
}
